
// Libraries
import * as React from 'react'
import { Link }  from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft  } from '@fortawesome/pro-regular-svg-icons'

// Services
import AuthService from '../services/auth'

// Components
import Layout from '../components/layout'
import DuoSection from '../components/duoSection'
import Seo from '../components/seo'
import InputFactory from '../components/inputFactory'
import Button from '../components/button'

// Initialise new instance of auth service
const authService = new AuthService()

class ForgottenPasswordPage extends React.Component {
	// Set initial state
	state = {
		formData: {},
		errors: {},
		isLoading: false
	}

	render() {
		// Get form data and errors from state
		const { formData, errors, isLoading } = this.state

		return <Layout className="page--login nav-blue-half">
			<Seo title="Forgotten Password" />
			<DuoSection login>
				<div>
					<div className="content-wrap">
						<p><Link to="/login" className="link--text color--endeavour"><FontAwesomeIcon icon={faChevronLeft} /> Back</Link></p>
						<h1 className="color--endeavour">Forgotten Password</h1>
						<form onSubmit={this.handleSubmit} autoComplete="off">
							<InputFactory
								type="email"
								name="email"
								placeholder="Email"
								value={formData?.email}
								error={errors?.email}
								onChange={this.handleInputChange}
								required
							/>
							<Button type="submit" isLoading={isLoading} colorEndeavour xsBlock>Submit</Button>
						</form>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {formData} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				formData[scope][name] = value
			}
			else {
				formData[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete formData[scope][name]
			}
			else {
				delete formData[name]
			}
		}

		// Update state of file input values
		this.setState({ formData })
	}

	handleSubmit = async (event) => {
		event.preventDefault()

		this.setState({
			isLoading: true
		})

		// Get form data from state
		const { formData } = this.state

		// Run forgotten password method from auth service
		const response = await authService.forgottenPassword(formData?.email)

		// Check if request was successful or if any validation errors
		if(!response?.success || response?.errors) {
			// Add validation errors to state
			this.setState({
				errors: response?.errors,
				isLoading: false
			})
		}
	}
}

export default ForgottenPasswordPage
